<template>
  <div id="page-users-list">
    <KCrudLayout :search.sync="searchQuery">
      <template #header>
        {{ $tc('candidate.title', 2) }}
      </template>
      <template #flow-actions>
        <v-btn
          color="primary"
          depressed
          tile
          @click="handleOpenCreateDialog"
        >
          {{ $t('actions.createResource', { resource: $tc('candidate.title', 1) }) }}
        </v-btn>
        <CandidateForm
          v-model="createDialog"
          :request="createRequest"
          :title="$tc('candidate.title',1)"
          :values="createFormValues"
          @change="$refs.table.reload()"
        />
      </template>
      <template #view.list>
        <k-crud-table
          ref="table"
          :headers="crudHeaders"
          :index-request="indexRequest"
          :search="searchQuery"
          language-prefix="candidate.crudHeaders"
          resource="candidate"
          show-expand
          single-expand
          @click:edit="openUpdate"
          @click:row="$router.push({ name: 'candidate.show', params: { candidateId: $event.id } })"
        >
          <template #item.createdAt="{ item }">
            {{ registrationDate(item.createdAt) }}
          </template>
          <template #item.positions="{ item }">
            {{ item.positions && `${item.positions}`.split(',').join(', ') }}
          </template>
          <template #expanded-item="{ item }">
            <v-row class="ma-0">
              <v-col cols="2">
                <VImg
                  v-if="item.profilePictureUrl"
                  :src="`${item.profilePictureUrl}&type=small`"
                  :lazy-src="`${item.profilePictureUrl}&type=lazy`"
                  width="100%"
                  :alt="item.name"
                />
              </v-col>
              <v-col cols="4">
                <div class="text-h6">
                  {{ item.name }}
                </div>
                <div class="subtitle-1">
                  {{ item.city }}
                </div>
                <div class="body-1 mt-12">
                  {{ item.motivation }}
                </div>
              </v-col>
              <v-col cols="3">
                <KLinkDisplay
                  :href="item.phone"
                  icon="$phone"
                  link-type="phone"
                />
                <KLinkDisplay
                  :href="item.email"
                  icon="$email"
                  link-type="email"
                />
              </v-col>
              <v-col cols="3">
                <div
                  v-if="item.maxTravelingTime"
                  class="k-text-display mb-2 d-flex"
                >
                  <v-icon
                    :color="item.transportationCar ? 'grey darken-2' : 'grey lighten-2'"
                    class="mr-4"
                  >
                    $car
                  </v-icon>
                  <v-icon
                    :color="item.transportationPublic ? 'grey darken-2' : 'grey lighten-2'"
                    class="mr-4"
                  >
                    $train
                  </v-icon>
                  <div class="k-value text--primary">
                    {{ item.maxTravelingTime }} {{ $tc('global.timeUnits.minute', item.maxTravelingTime) }}
                  </div>
                </div>
              </v-col>
            </v-row>
          </template>
        </k-crud-table>
      </template>
    </KCrudLayout>
    <CandidateForm
      v-model="updateDialog"
      :request="updateRequest"
      :title="$tc('candidate.title',1)"
      :values="updateFormValues"
      is-update-form
      @change="$refs.table.reload()"
    />
  </div>
</template>

<script>
import eventBus from '@/application/eventBus.ts';
import Candidate from '@/application/models/Candidate.js';
import KLinkDisplay from '@/components/crud/display/KLinkDisplay.vue';
import KCrudTable from '@/components/crud/KCrudTable.old.vue';
import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import { create, index, show, update } from '@/modules/candidate/api/candidate.js';
import CandidateForm from '@/modules/candidate/components/CandidateForm.vue';
import dayjs from '@/plugins/dayjs';

export default {
  name: 'CandidateIndex',
  components: {
    KLinkDisplay,
    CandidateForm,
    KCrudTable,
    KCrudLayout,
  },
  data() {
    return {
      searchQuery: '',
      updateDialog: false,
      createDialog: false,
      updateFormValues: new Candidate(),
      createFormValues: new Candidate(),
    };
  },
  computed: {
    crudHeaders() {
      return [
        {
          value: 'name',
          language: 'name',
        },
        {
          value: 'city',
          language: 'city',
        },
        {
          value: 'positions',
          language: 'positions',
          sortable: false,
        },
        {
          value: 'createdAt',
          language: 'createdAt',
        },
      ];
    },
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'candidate.index' },
        text: this.$tc('candidate.title', 2),
      },
    ]);
  },
  methods: {
    handleOpenCreateDialog() {
      this.createFormValues = new Candidate();
      this.createDialog = true;
    },
    updateRequest() {
      return update(this.updateFormValues);
    },
    createRequest() {
      return create(this.createFormValues);
    },
    indexRequest() {
      return index(...arguments);
    },
    async openUpdate(item) {
      this.updateFormValues = new Candidate();
      const candidateShowRequest = await show(item.id);
      this.updateFormValues.mapResponse(candidateShowRequest.data.data);
      this.updateDialog = true;
    },
    registrationDate(date) {
      return dayjs(date, 'YYYY-MM-DD HH:mm:ss').format('LL');
    },
  },
};
</script>
